import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthAdminGuardService } from './services/auth/auth-admin-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'holidays',
    //canActivate: [AuthGuardService],
    children: [
      {
        path: 'user',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/holidays/user/home/home.module').then(m => m.HomePageModule)
          },
          {
            path: 'department',
            loadChildren: () => import('./pages/holidays/user/department/department.module').then(m => m.DepartmentPageModule)
          },
          {
            path: 'notifications',
            loadChildren: () => import('./pages/holidays/user/notifications/notifications.module').then(m => m.NotificationsPageModule)
          },
          {
            path: 'profile',
            loadChildren: () => import('./pages/holidays/user/profile/profile.module').then(m => m.ProfilePageModule)
          },
          {
            path: 'request',
            loadChildren: () => import('./pages/holidays/user/request/request.module').then(m => m.RequestPageModule)
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/holidays/admin/home/home.module').then(m => m.HomePageModule)
          },
          {
            path: 'menu',
            loadChildren: () => import('./pages/holidays/admin/modals/menu/menu.module').then(m => m.MenuPageModule)
          },
          {
            path: 'assignment-type',
            loadChildren: () => import('./pages/holidays/admin/assignment-type/assignment-type.module').then(m => m.AssignmentTypePageModule)
          },
          {
            path: 'new-assignment',
            loadChildren: () => import('./pages/holidays/admin/modals/new-assignment/new-assignment.module').then(m => m.NewAssignmentPageModule)
          },
          {
            path: 'calendar-employee',
            loadChildren: () => import('./pages/holidays/admin/calendar-employee/calendar-employee.module').then(m => m.CalendarEmployeePageModule)
          },
          {
            path: 'calendar-employees',
            loadChildren: () => import('./pages/holidays/admin/calendar-employees/calendar-employees.module').then(m => m.CalendarEmployeesPageModule)
          },
          {
            path: 'calendar-general',
            loadChildren: () => import('./pages/holidays/admin/calendar-general/calendar-general.module').then(m => m.CalendarGeneralPageModule)
          },
          {
            path: 'employees',
            loadChildren: () => import('./pages/holidays/admin/employees/employees.module').then(m => m.EmployeesPageModule)
          },
          {
            path: 'reports',
            loadChildren: () => import('./pages/holidays/admin/reports/reports.module').then(m => m.ReportsPageModule)
          },
          {
            path: 'list-non-working-days',
            loadChildren: () => import('./pages/holidays/admin/modals/list-non-working-days/list-non-working-days.module').then( m => m.ListNonWorkingDaysPageModule)
          },
          {
            path: 'add-employees',
            loadChildren: () => import('./pages/holidays/admin/modals/add-employees/add-employees.module').then( m => m.AddEmployeesPageModule)
          },
          {
            path: 'reports-homework',
            loadChildren: () => import('./pages/holidays/admin/reports-homework/reports-homework.module').then( m => m.ReportsHomeworkPageModule)
          }
        ]
      }
    ]
  },
  {
    path: 'vehicles',
    //canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/vehicles/home/home.module').then(m => m.HomePageModule)
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/vehicles/user/home/home.module').then(m => m.HomePageModule)
          },
          {
            path: 'my-request',
            loadChildren: () => import('./pages/vehicles/user/my-request/my-request.module').then(m => m.MyRequestPageModule)
          },
          {
            path: 'request',
            loadChildren: () => import('./pages/vehicles/user/request/request.module').then(m => m.RequestPageModule)
          },
          {
            path: 'result-request',
            loadChildren: () => import('./pages/vehicles/user/result-request/result-request.module').then(m => m.ResultRequestPageModule)
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/vehicles/admin/home/home.module').then(m => m.HomePageModule)
          },
          {
            path: 'menu',
            loadChildren: () => import('./pages/vehicles/modals/menu/menu.module').then(m => m.MenuPageModule)
          },
          {
            path: 'company-request',
            loadChildren: () => import('./pages/vehicles/admin/company-request/company-request.module').then(m => m.CompanyRequestPageModule)
          },
          {
            path: 'historic-company',
            loadChildren: () => import('./pages/vehicles/admin/historic-request/historic-request.module').then( m => m.HistoricRequestPageModule)
          },
          {
            path: 'renting-request',
            loadChildren: () => import('./pages/vehicles/admin/renting-request/renting-request.module').then( m => m.RentingRequestPageModule)
          },
          {
            path: 'historic-renting',
            loadChildren: () => import('./pages/vehicles/admin/historic-renting-request/historic-renting-request.module').then( m => m.HistoricRentingRequestPageModule)
          },
          {
            path: 'request',
            loadChildren: () => import('./pages/vehicles/admin/request/request.module').then( m => m.RequestPageModule)
          },
          {
            path: 'employees',
            canActivate: [AuthAdminGuardService],
            loadChildren: () => import('./pages/vehicles/admin/employees/employees.module').then( m => m.EmployeesPageModule)
          },
          {
            path: 'new-employee',
            canActivate: [AuthAdminGuardService],
            loadChildren: () => import('./pages/vehicles/admin/modals/new-employee/new-employee.module').then( m => m.NewEmployeePageModule)
          },
          {
            path: 'load',
            canActivate: [AuthAdminGuardService],
            loadChildren: () => import('./pages/vehicles/admin/load-file/load-file.module').then( m => m.LoadFilePageModule)
          }
        ]
      }
    ]
  },
  //"login?activity=account&mode=admin&token=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.MdDYAUgST-OoQRJfTyvC2oi47GiPImAbGc4LgItzoKjE7fEHxSpC1-sQloKP1YTlbcRF1sN8PUiF7vK3eYAeQ8gqaRH4ZX4p.-YqRScuj0l_tgHvundoGbw.5hv0F841-l23-emMStrKxzau7Sj-CDvPQPgt8s8WnjwVMSpiZMPLjI-5W340_OTgWX7ENzF7kpgk8kxWZexkFOzzIY0dqZSYlkdoSQCSzUfcd0KLrKvVlJbAX1lPlFW9MafxHcr4PdtpDqJmUuBlvTwq2BSYzQ1OkG3bpsWHoZVstxGHDUysoMAYAo1SxBb_K-UYsdIFGMu83kA51uAzbIeYU19xt37eA6Ul4cCSEec39tTUyMz44nRQ-3W2AdK19AgPnre2Cwd41YvOwBVnyi-bLCk00VcVUZRNVL5cGNP1JJmorLMr-V74G7VNw93SSfjNYzM6JXheqz_V3rbK0iBWNYFeY3arB2e_TYkdFnMeDQ-GOVJZV554lZ13hts4Tjokne8fHChdipVdWcGX_IcuCbsrA_etJltPwaPxtHlV5rMpLXHHqIDRFcTmUyfp3H05JeAoWpu1oky9HTYdDvfZu45VOcgeaKnWf3xc324.0IICL5J4FXzy-ErJXKYgN4bxFqa082IXFRaMGi4xxG0
  {
    path: 'account',
    //canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/account/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/account/admin/home/home.module').then( m => m.HomePageModule)
          },
          {
            path: 'detail',
            loadChildren: () => import('./pages/account/admin/detail/detail.module').then( m => m.DetailPageModule)
          }
        ]
      },
      {
        path: 'new-employees',
        children:[
          {
            path: '',
            loadChildren: ()=> import('./pages/account/new-employees/new-employees.module').then(m =>m.NewEmployeesPageModule)
          }
        ]
      },
      {
        path: 'remove-employees',
        children:[
          {
            path: '',
            loadChildren: ()=> import('./pages/account/remove-employees/remove-employees.module').then(m =>m.RemoveEmployeesPageModule)
          }
        ]
      },
      
      {
        path: 'modify-employee',
        children:[
          {
            path: '',
            loadChildren: () => import('./pages/account/modify-employee/modify-employee.module').then( m => m.ModifyEmployeePageModule)
          }
        ]
      },

      {
        path: 'add-company',
        children:[
          {
            path: '',
            loadChildren: () => import('./pages/account/add-company/add-company.module').then( m => m.AddCompanyPageModule)
          }
        ]
      },
    ]
  },
  {
    path: 'gamelearn',
    //canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/gamelearn/gamelearn.module').then( m => m.GamelearnPageModule)
      }
  
    ]
  },
  {
    path: 'chatbot',
    //canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/chatbot/chatbot.module').then( m => m.ChatbotPageModule)
      }
  
    ]
  },

  {
    path: 'error-screen',
    loadChildren: () => import('./pages/modals/error-screen/error-screen.module').then(m => m.ErrorScreenPageModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./pages/email-result/request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'result-request',
    loadChildren: () => import('./pages/modals/result-request/result-request.module').then( m => m.ResultRequestPageModule)
  },
  {
    path: 'confirm-vehicle-request',
    loadChildren: () => import('./pages/vehicles/admin/modals/confirm-vehicle-request/confirm-vehicle-request.module').then( m => m.ConfirmVehicleRequestPageModule)
  },
  {
    path: 'instructions-consumption',
    loadChildren: () => import('./pages/modals/instructions-consumption/instructions-consumption.module').then( m => m.InstructionsConsumptionPageModule)
  },
  {
    path: 'aproved-list',
    loadChildren: () => import('./pages/modals/aproved-list/aproved-list.module').then( m => m.AprovedListPageModule)
  },
  {
    path: 'compensation-list',
    loadChildren: () => import('./pages/modals/compensation-list/compensation-list.module').then( m => m.CompensationListPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
