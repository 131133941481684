import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const textToDelete = value.indexOf("Para más detalles");
    if (textToDelete !== -1) {
      value = value.substring(0, textToDelete).trim();
    }
    
    // Reemplazar saltos de línea dobles por <p></p>
    let formattedText = value.replace(/\n\n/g, '</p><p>');
    
    // Reemplazar saltos de línea simples por <br>
    formattedText = formattedText.replace(/\n/g, '<br>');
    
    // Reemplazar negritas en formato Markdown (**texto**) por <b>texto</b>
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    
    // Reemplazar cursivas en formato Markdown (*texto*) por <i>texto</i>
    formattedText = formattedText.replace(/\*(.*?)\*/g, '<i>$1</i>');
    
    // Reemplazar subrayado en formato Markdown (__texto__) por <u>texto</u>
    formattedText = formattedText.replace(/__([^_]+)__/g, '<u>$1</u>');
    
    // Reemplazar encabezados en formato Markdown (# Título) por <h1>Título</h1>
    formattedText = formattedText.replace(/^# (.*$)/gm, '<h1>$1</h1>');
    formattedText = formattedText.replace(/^## (.*$)/gm, '<h2>$1</h2>');
    formattedText = formattedText.replace(/^### (.*$)/gm, '<h3>$1</h3>');
    
    // Reemplazar listas con guion (- item) por <ul><li>item</li></ul>
    formattedText = formattedText.replace(/^- (.*)$/gm, '<ul><li>$1</li></ul>');
    formattedText = formattedText.replace(/(<\/ul>\n<ul>)/g, ''); // Unir listas consecutivas
    
    // Reemplazar listas numeradas (1. item) por <ol><li>item</li></ol>
    formattedText = formattedText.replace(/^\d+\. (.*)$/gm, '<ol><li>$1</li></ol>');
    formattedText = formattedText.replace(/(<\/ol>\n<ol>)/g, ''); // Unir listas consecutivas

    return `${formattedText}`;
  }
}
