import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbsPipe } from './abs.pipe';
import { BrandTranslatePipe } from './brand-translate.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { CheckSameSenderPipe } from './check-same-sender.pipe';
import { FormatTimePipe } from './format-time';
import { IsMinePipe } from './is-mine.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { UrlifyPipe } from './urlify.pipe';
import { InitialsPipe } from './initials.pipe';



@NgModule({
  declarations: [AbsPipe, NumberFormatPipe, BrandTranslatePipe, CheckSameSenderPipe,FormatTimePipe, IsMinePipe, SanitizePipe,UrlifyPipe, InitialsPipe],
  imports: [
    CommonModule
  ],
  exports: [AbsPipe, NumberFormatPipe, BrandTranslatePipe, CheckSameSenderPipe,FormatTimePipe, IsMinePipe, SanitizePipe,UrlifyPipe, InitialsPipe]
})
export class PipesModule { }
